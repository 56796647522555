// vue
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';
import { useRefs } from '@store/ts/refs';

// shared
import { VA_SETTINGS_FORM_FACTORY, VA_PD_FILTER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown, formatUnexistedCurrsAsUnknownObject } from '@shared/util';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ExchangesService from '@services/exchangesService';
import ToolsService from '@services/tools';
import fromExponential from 'from-exponential';

// UI
import { useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const tools = useTools();
    const refs = useRefs();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const markets = ref();
    const init = ref(false);
    const botTvaResultsBlock = ref();
    const sharedCoinList = ref();
    const vaResultsFetched = ref();
    const sharedCoinListRef = ref();
    const dataForm = reactive({});
    const errorsPresetField = reactive({
        name: {
            status: null,
            msg: null,
        },
    });
    const continueSavingPreset = ref(false);
    const symbolsForMarketRefs = ref();

    const isMarketSelected = computed(() => dataForm.market.value !== -1);
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
    const useBwList = computed(() => !!dataForm.use_bw_list.value);
    const marketSelected = computed(() => dataForm.market.value && parseInt(dataForm.market.value, 10) !== -1);
    const canShowSharedCoinList = computed(() =>
        ['shared', 'local_and_shared'].includes(dataForm.coin_list_strategy)
      && dataForm.shared_coin_list != -1);
    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const coinListStrategiesOptions = computed(() => tools.coinListStrategies.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const symbolsForMarketRefsOptions = computed(() => 
        symbolsForMarketRefs.value
            ? Object.values(symbolsForMarketRefs.value).map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            }))
            : []);
  
    const periodUnitsOptions = computed(() => tools.periodUnits.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));
  
    const intervalUnitsOptions = computed(() => tools.intervalUnits.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));
  
    const sharedCoinListItems = computed(() => {
        if (dataForm.exchange.value == -1) return [];

        return tools.coinLists
            .filter(cl => cl.exchange == dataForm.exchange.value)
            .map(el => ({
                label: el.title,
                value: el.id,
                ...el,
            }));
    });

    const currencyOptions = computed(() => Array.isArray(markets.value)
        ? formatUnexistedCurrsAsUnknown(refs, markets.value).map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }))
        : []);

    watch(() => dataForm.exchange?.value, v => {
        if (!tools.currentPreset) {
            dataForm.market.value = -1;
        } else {
            tools.currentPreset = null;
        }
      
        if (v !== -1)
            getMarkets(v);
    });

    watch(() => dataForm.shared_coin_list, v => {
        if (v === null) {
            sharedCoinList.value = null;
        }

        if (v != -1)
            fetchSharedCoinListRef(v);
    });

    watch(() => dataForm.market?.value, async newVal => {
        if (!newVal || newVal == -1) return;

        getSymbolsForMarket();
    });

    const initModule = () => {
        const res = tools.currentPreset || VA_SETTINGS_FORM_FACTORY();

        for (const [key, value] of Object.entries(res)) {
            if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_create_new_preset_exchange_f'],
                    dataHelp: tools.help['switch_exchange'],
                    placeholder: tools.localization['tools/va/coin_list/select_exchange'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'market') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_create_new_preset_market_f'],
                    dataHelp: tools.help['switch_market'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                    show: computed(() => isExchangeSelected.value),
                };
            } else if (key === 'use_bw_list') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_black_white_lists'],
                    dataHelp: tools.help['switch_use_bw_list'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                    show: computed(() => isMarketSelected.value),
                };
            } else if (key === 'period_value') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_period'],
                    dataHelp: tools.help['switch_period'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (key === 'interval_value') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_interval'],
                    dataHelp: tools.help['switch_interval'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (key === 'rate_change') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_rate_change_f'],
                    dataHelp: tools.help['switch_rate_change'],
                    placeholder: tools.localization['tools_volatility_analyzer_rate_change_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (key === 'min_rate_changes_count') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_min_rate_change_count_f'],
                    dataHelp: tools.help['switch_min_rate_change_count'],
                    placeholder: tools.localization['tools_volatility_analyzer_min_rate_change_count_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (key === 'min_trade_volume') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_min_trade_volume_f'],
                    dataHelp: tools.help['switch_min_trade_volume'],
                    placeholder: tools.localization['tools_volatility_analyzer_min_trade_volume_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (key === 'min_price') {
                dataForm[key] = {
                    value,
                    title: tools.localization['tools_volatility_analyzer_min_price_f'],
                    dataHelp: tools.help['switch_min_price'],
                    placeholder: tools.localization['tools_volatility_analyzer_min_price_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    customField: true,
                };
            } else if (['period_unit', 'interval_unit'].includes(key)) {
                dataForm[key] = +value;
            } else dataForm[key] = value;
        };

        init.value = true;
    };

    const getMarkets = async id => {
        gl.showLoading = true;
    
        try {
            const result = ( await ExchangesService.getMarkets({
                ids: [id],
            }) ).data;

            if (result?.errors) {
                result.errors.forEach(({ msg }) => notification.error({
                    content: msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                }));
            } else {
                markets.value = result.markets;
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        gl.showLoading = false;
    };

    const getSymbolsForMarket = async () => {
        gl.showLoading = true;

        try {
            var prepare = ( await ExchangesService.getSymbolsForMarket({
                exchange: dataForm.exchange.value,
                market: dataForm.market.value,
            }) ).data;

            symbolsForMarketRefs.value = formatUnexistedCurrsAsUnknownObject(refs, prepare.symbols || []);
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }

        gl.showLoading = false;
    };

    const fetchSharedCoinListRef = async id => {
        gl.showLoading = true;

        try {
            var data = await ToolsService.getCoinListInfo(id);

            if (!data?.data.status) {
                notification.error({
                    content: data.data?.message,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            } else {
                sharedCoinList.value = data.data.coin_list.coins;
                sharedCoinListRef.value = formatUnexistedCurrsAsUnknown(refs, data.data.coin_list.coins).map(el => ({
                    value: el.id,
                    label: el.title,
                    ...el,
                }));
            };
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }
      
        gl.showLoading = false;
    };

    const onRunPreset = async () => {
        gl.showLoading = true;
        gl.loadingMessage = tools.localization['tools_volatility_analyzer_run_analyzer_spinner_message'];

        const pd_filters = [...dataForm.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
        const prepare = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);

        const payload = {
            ...prepare,
            bw_list: useBwList.value ? dataForm.bw_list : [],
            pd_filters,
            is_white_list: useBwList.value ? dataForm.is_white_list : false,
            shared_coin_list: useBwList.value ? dataForm.shared_coin_list : '-1',
            min_price: fromExponential(dataForm.min_price.value),
            _formPath: 'tvaSettings',
            _source: 'tools',
        };

        try {
            var data = await ToolsService.runPreset(payload);

            if (!data.data.status) {
                if (data.data?.errors_form) {

                    for (let key in dataForm) {
                        const fields = data.data.errors_form.tvaSettings.fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (typeof dataForm[key] === 'object' && dataForm[key] !== null) {
                            if (el) {
                                dataForm[key].status = 'error';
                                dataForm[key].msg = fields[el].msg;
                            } else {
                                dataForm[key].status = 'success';
                                dataForm[key].msg = undefined;
                            }
                        }
                    }
                }
            } else {
                // show messages
                data.postMessages.forEach(el => {
                    gl.showNotification({
                        type: el.success ? 'success' : 'error',
                        msg: el.msg,
                    });
                });

                vaResultsFetched.value = data.data;

                setTimeout(() => {
                    botTvaResultsBlock.value.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                });
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }
      
        gl.loadingMessage = '';
        gl.showLoading = false;
    };

    const getRefs = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            tools.refs = ( await ToolsService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
      
        if (showLoading)
            gl.showLoading = false;
    };

    const onSavePreset = async () => {
        gl.showLoading = true;

        const pd_filters = [...dataForm.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
        const prepare = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);

        const payload = {
            ...prepare,
            bw_list: useBwList.value ? dataForm.bw_list : [],
            pd_filters,
            is_white_list: useBwList.value ? dataForm.is_white_list : false,
            min_price: fromExponential(dataForm.min_price.value),
            shared_coin_list: useBwList.value ? dataForm.shared_coin_list : '-1',
            _formPath: 'tvaSettings',
            _source: 'tools',
        };

        try {
            var data = await ToolsService.savePreset(payload);

            if (!data?.data.status) {
                for (const [key, value] of Object.entries(data.data.errors_form)) {
                    errorsPresetField[key] = {
                        ...value,
                        status: 'error',
                    };
                }
            } else {
                const { id, title, message: msg } = data.data;
                notification.success({
                    content: msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
                tools.currentPresetId = id;
                await getRefs();
              
                context.emit('chacngeTab', 'rbpsPresets');

            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }

        gl.showLoading = false;
    };

    const onDeletePDFilter = i => {
        dataForm.pd_filters.splice(i, 1);
    };

    const onAddPDFilter = () => {
        dataForm.pd_filters.push(VA_PD_FILTER_FACTORY());
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    onMounted(() => {
        initModule();
    });

    onUnmounted(() => {
        // tools.currentPreset = null;
    });

    return {
        gl,
        init,
        tools,
        markets,
        dataForm,
        marketSelected,
        sharedCoinList,
        exchangeOptions,
        currencyOptions,
        isMarketSelected,
        errorsPresetField,
        vaResultsFetched,
        botTvaResultsBlock,
        sharedCoinListRef,
        periodUnitsOptions,
        sharedCoinListItems,
        continueSavingPreset,
        intervalUnitsOptions,
        canShowSharedCoinList,
        coinListStrategiesOptions,
        symbolsForMarketRefsOptions,
        onRunPreset,
        onSavePreset,
        onAddPDFilter,
        onDeletePDFilter,
    };
}