<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
    }">
    <rb-presets
        :title="tools.localization['tools_volatility_analyzer_presets_info']"
        :presets="refsVaPresetsOk"
        :secondPresets="refsVaPresetsZero"
        :currPresetId="currPresetId"
        @presetChanged="presetChanged">
    </rb-presets>

    <div
        v-if="currPresetId"
        class="flex justify-center mt-4">
        <n-button
            class="text-white/90 rounded-md"
            :color="gl.mainColor"
            @click="onApplyPreset">
            {{ tools.localization['tools_volatility_analyzer_presets_apply_presets_button'] }}
        </n-button>
        <n-button
            class="mx-4 text-white/90 rounded-md"
            :color="gl.mainColor"
            @click="onRunPreset">
            {{ tools.localization['tools_volatility_analyzer_run_analyzer_button'] }}
        </n-button>
        <n-button
            class="text-white/90 rounded-md"
            :color="gl.mainColor"
            @click="onDeletePreset">
            {{ tools.localization['tools_volatility_analyzer_presets_delete_preset_button'] }}
        </n-button>
    </div>

    <super-table
        v-if="vaResultsFetched"
        ref="botTvaResultsBlock"
        :refresh="false"
        :showFooter="false"
        :pager="vaResultsFetched.pager"
        :columns="vaResultsFetched.columns"
        :records="vaResultsFetched.records"
        columnsStorageName="__columns_volatility-analyzer-presets" />
</n-card>
</template>

<script>
// vue
import { ref, watch, computed, onMounted, onUnmounted } from 'vue';

// components
import RbPresets from '@components/rb-presets';
import SuperTable from '@components/super-table/desktop.vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ToolsService from '@services/tools';

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';

// naive-ui
import {
    NCard,
    NButton,
    NDivider,
    useNotification } from 'naive-ui';

export default {
    name: 'Presets',
    components: {
        NCard,
        RbPresets,
        NDivider,
        NButton,
        SuperTable,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const tools = useTools();

        // i18n
        const { t } = useI18n();

        // naive-ui
        const notification = useNotification();

        // vars
        const { _ } = window;
        const vaResultsFetched = ref();
        const botTvaResultsBlock = ref();
        const currPresetId = ref();

        const refsVaPresetsOk = computed(() => _.pickBy(tools.vaPresets, i => i.ref_id != 0));
        const refsVaPresetsZero = computed(() => _.pickBy(tools.vaPresets, i => i.ref_id == 0));

        const presetChanged = $event => {
            currPresetId.value = $event;
        };

        watch(currPresetId, () => {
            vaResultsFetched.value = null;
        });

        const onApplyPreset = async () => {
            gl.showLoading = true;

            try {
                var data = await ToolsService.getPresetInfo(currPresetId.value);

                if (!data?.data.status) {
                    notification.error({
                        content: data.postMessages[0].msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                } else {
                    tools.currentPreset = data.data.preset;
                    emit('chacngeTab', 'rbpsSettings');
                };
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }

            gl.showLoading = false;
        };

        const onRunPreset = async () => {
            gl.showLoading = true;
            gl.loadingMessage = tools.localization['tools_volatility_analyzer_run_analyzer_spinner_message'];

            const payload = {
                id: currPresetId.value,
                _formPath: 'tvaPresets',
                _source: 'tools',
            };

            try {
                var data = await ToolsService.runPreset(payload);

                if (!data.data.status) {
                    notification.error({
                        content: t('errorMessage'),
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                } else {
                    // show messages
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                    vaResultsFetched.value = data.data;

                    setTimeout(() => {
                        botTvaResultsBlock.value.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    });
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
            
            gl.loadingMessage = '';
            gl.showLoading = false;
        };

        const onDeletePreset = async () => {
            gl.showLoading = true;

            try {
                const data = await ToolsService.deletePreset(currPresetId.value);

                if (!data.data.status) {
                    notification.error({
                        content: t('errorMessage'),
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                } else {
                    // show messages
                    data.data.messages.forEach(el => {
                        notification.success({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                    tools.vaPresets.splice(tools.vaPresets.findIndex(({ id }) => id === currPresetId.value), 1);

                    currPresetId.value = null;
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
    
            gl.showLoading = false;
        };

        onMounted(() => {
            currPresetId.value = tools.currentPresetId;
        });

        onUnmounted(() => {
            tools.currentPresetId = null;
        });

        return {
            gl,
            tools,
            currPresetId,
            refsVaPresetsOk,
            vaResultsFetched,
            refsVaPresetsZero,
            botTvaResultsBlock,
            onRunPreset,
            presetChanged,
            onApplyPreset,
            onDeletePreset,
        };
    },
};
</script>