// vue
import { ref, reactive, watch, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// shared
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// services
import ToolsService from '@services/tools';
import ExchangesService from '@services/exchangesService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
// store
    const gl = useGl();
    const refs = useRefs();
    const tools = useTools();

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();

    // vars
    const { _ } = window;
    const syncLock = ref(false);
    const exchangeInfo = ref();
    const currCoinListId = ref();
    const exchangeCurrencies = ref();
    const exchangesCache = reactive({});
    const COIN_LIST_FORM_FACTORY = ({id = null, title = '', exchange = -1, coins = []} = {} ) => ({
        id,
        title,
        exchange,
        coins,
    });
    const dataForm = ref(COIN_LIST_FORM_FACTORY());

    const refsCoinLists = computed(() => tools.coinLists);

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const coinListFilled = computed(() => {
        const {title, exchange, coins} = dataForm.value;
        return title.trim() && exchange != -1 && coins.length > 0;
    });
    const canDelete = computed(() => dataForm.value.id);
    const canCreate = computed(() => coinListFilled.value);
    const canUpdate = computed(() => dataForm.value.id && coinListFilled.value);

    watch(() => dataForm.value.exchange, async v => {
        if (v != -1 && !syncLock.value) {
            getFullInfo(v);
        } else if (v === -1) {
            exchangeCurrencies.value = [];
        }
    });

    const getFullInfo = async id => {
        gl.showLoading = true;

        try {
            exchangeInfo.value = ( await ExchangesService.getFullInfo(id) ).data;

            exchangeCurrencies.value = formatUnexistedCurrsAsUnknown(refs, exchangeInfo.value.currencies).map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }));
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const onAddAllExchangeCoins = () => {
        dataForm.value.coins = exchangeCurrencies.value.map(c => _.get(c, 'id'));
    };
    const onClearAllExchangeCoins = () => {
        dataForm.value.coins = [];
    };

    const onCreateCoinList = async () => {
        gl.showLoading = true;

        try {
            const coin_list = { ...dataForm.value };
            delete coin_list.id;

            const data = await ToolsService.createCoinList({ coin_list });

            if (!data.data.status) {
                if (data.data?.errors_form) {
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
                }
            } else {
            // show messages
                data.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                dataForm.value = COIN_LIST_FORM_FACTORY();

                tools.coinLists.push(data.data.coin_list);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const onUpdateCoinList = async () => {
        gl.showLoading = true;

        try {
        
            const data = await ToolsService.updateCoinList(dataForm.value);

            if (!data.data.status) {
                if (data.data?.errors_form) {
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
                }
            } else {
            // show messages
                data.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                tools.coinLists[tools.coinLists.findIndex(({ id }) => id === dataForm.value.id)] = { ...data.data.coin_list, id: dataForm.value.id };
            
                onCancelCoinList();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const onCancelCoinList = () => {
        dataForm.value = COIN_LIST_FORM_FACTORY();
        currCoinListId.value = null;
    };

    const onDeleteCoinList = async () => {
        gl.showLoading = true;

        try {
            const data = await ToolsService.deleteCoinList(dataForm.value.id);

            if (!data.data.status) {
                if (data.data?.errors_form) {
                    data.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
                }
            } else {
            // show messages
                data.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                tools.coinLists.splice(tools.coinLists.findIndex(({ id }) => id === dataForm.value.id), 1);
            
                onCancelCoinList();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const onCoinListItemSelected = async coinList => {
        syncLock.value = true;
        currCoinListId.value = coinList.id;
        const strId = String(coinList.id);

        const setDF = (c) => {
            dataForm.value.coins = c.coins;
            dataForm.value.title = c.title;
            dataForm.value.id = c.id;
            dataForm.value.exchange = c.exchange;

            setTimeout(() => {
                syncLock.value = false;
            });
        };

        if (strId in exchangesCache) {
            exchangeCurrencies.value = exchangesCache[strId];
            setDF(coinList);
        } else {
            await getFullInfo(coinList.exchange);
            exchangesCache[strId] = exchangeCurrencies.value;
            setDF(coinList);
        }

        setTimeout(() => {
            document
                .querySelector('#coint-list-alert')
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        });
    };

    return {
        gl,
        refs,
        tools,
        dataForm,
        canCreate,
        canUpdate,
        canDelete,
        refsCoinLists,
        currCoinListId,
        exchangeOptions,
        exchangeCurrencies,
        exchange,
        getFullInfo,
        onCreateCoinList,
        onUpdateCoinList,
        onCancelCoinList,
        onDeleteCoinList,
        onAddAllExchangeCoins,
        onCoinListItemSelected,
        onClearAllExchangeCoins,
    };
}